export var M_CHECKBOXES_REGEX = "\\$MULTIPLE_CHECKBOXES\\$";
export var DFRULES_REGEX = '\\$DFRULES\\$';
export var DFID_TAG = 'DFID_';
export var DFID_VALUE_TAG = '[a-zA-Z1-9_-]{1,}';
export var DFID_REGEX = "\\$".concat(DFID_TAG).concat(DFID_VALUE_TAG, "\\$");
export function hasMCheckboxesTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp("^".concat(M_CHECKBOXES_REGEX))));
}
export function hasDFRulesTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp("^".concat(DFRULES_REGEX))));
}
export function removeAllTag(name) {
    return (name || '').replace(new RegExp([
        '^(',
        [DFRULES_REGEX, M_CHECKBOXES_REGEX, DFID_REGEX].join('|'),
        '){1,}'
    ].join('')), '');
}
